document.addEventListener("DOMContentLoaded", function(event) {

  var atys_add_nodes = document.querySelectorAll('.atys_add')

  for (var j = 0; j < atys_add_nodes.length; j++) {



    atys_add_nodes[j].addEventListener('click', function() {
      console.log(this.parentNode.querySelector('.atys_template'))



      var clone = this.parentNode.querySelector('.atys_template').cloneNode('false');
      inputs = clone.querySelectorAll('input')
      for (var i = 0; i < inputs.length; i++) {
        if (inputs[i].type == 'checkbox') {
          inputs[i].checked = false;
        } else {
          inputs[i].value = '';
        }
        // console.log(inputs[i])
      }
      imgs = clone.querySelectorAll('img')
      for (var i = 0; i < imgs.length; i++) {

        imgs[i].src = '';
      }
      // console.log(imgs[i])

      this.parentNode.querySelector('.atys_container').appendChild(clone);

    })
  }
  var elems = document.querySelectorAll('.atys_container'),
    i

  for (i = 0; i < elems.length; ++i) {
    elems[i].addEventListener('click', function(e) {
      if (!e.target.classList.contains("atys_remove"))
        return false;
      var elTarget = e.target
      while (!elTarget.classList.contains("atys_template") && elTarget.parentNode) {
        elTarget = elTarget.parentNode;
        // console.log(elTarget)
      }
      elTarget.parentNode.removeChild(elTarget);

      return false;


    })
  }
})
