$(document).ready(function() {


    $(".FieldsetToggle").click(function() {

        //  console.log('click');
        $(this).parent().toggleClass('collapsed');
        localStorage['toggle_' + $(this).parent().attr('id')] = $(this).parent().hasClass('collapsed');
        //  console.log($(this).parent().attr('id'));
    });

    /* settings for user preferences */
    for (i = 0; i < localStorage.length; i++) {

        //    console.log(localStorage.key(i), localStorage[localStorage.key(i)])
        if (localStorage[localStorage.key(i)] == 'true' && localStorage.key(i).indexOf('toggle') + 1) {
            $('#' + localStorage.key(i).substr(7) + "> .FieldsetToggle ").click();
            //    console.log(localStorage.key(i).substr(7))
        }
    }


    // $("input:disabled").dblclick(function(){
    //     console.log('disable');
    //     this.attr('disabled', false)
    //     return false;
    // })

    //   $('.t_pics .j-edit').dblclick(function(e) {
    //     var elem = $(e.target);
    //     var content = elem.html()
    //     var width = content.length;
    //     console.log(elem.html(),width)
    //     elem.attr('data-x',content)
    //     var menu = "<div class='jmenu'><span title=save class='iconafter update' data-icon='&#x2713;'> </span><span title=cancel class='iconafter cancel ' data-icon='&#xe009;'> </span></div>"
    //     elem.html("<input class='fl' type=text size="+width +" data-x='"+content+"' name=name value="+content+">"+menu)
    //
    //     elem.find('.update').bind("click",function(){
    //          console.log(elem.attr('data-x'),elem.find('input').val())
    //       $.post('admin/file_rename',  {oldname: elem.attr('data-x'), newname:  elem.find('input').val()}, function(data){
    //           console.log("loaded "+data);
    //           elem.html(elem.find('input').val())
    //       })
    //     })
    //     elem.find('.cancel').one("click",function(){
    //       elem.html(elem.attr('data-x'))
    //     })
    // })

    // $('#title').blur(function(){
    //     var t = this.value.toLowerCase();
    //     t=$.trim(t);
    //     t = t.replace(/ /g,"-")
    //     if (!$('#slug').attr('value')) $('#slug').attr('value',t).attr('disabled','');
    // })

    // $("#l_edit").click(function () {
    //     return !$("#d_edit").toggle("slow");
    // });
    //
    // $("#l_editTags").click(function () {
    //     return !$("#d_editTags").toggle("slow");
    // });


    (function() {
        var unsaved = false;

        $(".atys_warning :input").change(function() { //trigers change in all input fields including text type
            unsaved = true;
        });

        function unloadPage() {
            if (unsaved) {
                return "You have unsaved changes on this page. Do you want to leave this page and discard your changes or stay on this page?";
            }
        }
        $(':submit').click(function() {
            unsaved = false;
        });
        window.onbeforeunload = unloadPage;
    })();


    //  $(".a_update_l").click(function() {
    //    //        Hack to prevent not saving tinymce stuff, fixed
    //    //        $(".a_update_f textarea").each(function(index,obj){
    //    //            tinyMCE.execCommand('mceRemoveControl', false, $(obj).attr('id'));
    //    //        })
    //    // ajax update page form
    //    $.post($(".a_update_f").attr('action'), $(".a_update_f").serialize(),
    //      function(data) {}, "html");
    //  });


    $('.Form-clear').each(function() {
        var searchclear = document.createElement('span');
        searchclear.setAttribute("class", "fa fa-times searchclear-button");
        searchclear.addEventListener('click', function() {
            if (/input/i.test(this.previousElementSibling.nodeName)) {
                this.previousElementSibling.value = "";
            } else {
                console.log(this.previousElementSibling);
                el = this.previousElementSibling.querySelector('input.Form-clear');
                if (el) {
                    el.value = "";
                }
                console.log();
            }
        }, false);
        this.parentNode.insertBefore(searchclear, this.nextSibling);

    });

    // $('.data-atys-add').each(function(){
    //     var addbutton = document.createElement('span');
    //     addbutton.setAttribute("class","fa fa-plus atys-add-button");
    //     addbutton.addEventListener('click',   function() {
    //         var container = this.parentNode;
    //         if (/div/i.test(container.nodeName)) {
    //           this.previousElementSibling. = "";
    //           document.parentNode
    //
    //
    //         } else {
    //
    //
    //         }
    //       }, false)
    //     this.parentNode.insertBefore(addbutton, this.nextSibling);
    //
    // })




    // $('[data-searchclear]').on("click",
    //   function() {
    //     if (/input/i.test(this.previousElementSibling.nodeName)) {
    //       this.previousElementSibling.value = "";
    //     } else {
    //       console.log(this.previousElementSibling);
    //       el = this.previousElementSibling.querySelector('input');
    //       if (el) {
    //         el.value = ""
    //       }
    //
    //     }
    //   })


    //
    //   $(document).ready(function() {
    //     //  $.ajaxSetup({
    //     //  headers: {
    //     //  'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    //     //  }
    //     //  });
    // if (document.querySelector(".ajasomplete")){
    //     var awesomplete = new Awesomplete(document.querySelector(".ajasomplete"), {
    //       maxItems: 30,
    //       item: function(text, input) {
    //         return Awesomplete.$.create("li", {
    //           innerHTML: "<img src=/cache/imagecache/grid2/" + text.split('/').reverse()[0] + " ><span>" + text + "</span>",
    //           "aria-selected": "false"
    //         });
    //       },
    //       replace: function(text) {
    //         this.input.value = text
    //         this.input.parentNode.previousElementSibling.innerHTML = "<img src=/cache/imagecache/thumbnail/" + text.split('/').reverse()[0] + " >"
    //       }
    //     });
    //
    //     $.ajax({
    //       url: "/admin/media",
    //       type: "get",
    //       dataType: "json",
    //       success: function(json) {
    //
    //         // console.log(json);
    //         if (awesomplete)
    //           awesomplete.list = json
    //       },
    //
    //       // Code to run if the request fails; the raw request and
    //       // status codes are passed to the function
    //       error: function(xhr, status, errorThrown) {
    //         // alert("Sorry, there was a Aweproblem!");
    //         console.log("Error: " + errorThrown);
    //         // console.log("Status: " + status);
    //         // console.dir(xhr);
    //       },
    //
    //       // Code to run regardless of success or failure
    //       complete: function(xhr, status) {
    //
    //
    //       }
    //     });
    // }
    //   })



if ($.fn.tooltip)
    $('[data-toggle]').tooltip();
if ($.fn.seelct2)
    $('.select2').select2();


    if (document.querySelectorAll('.ajasomplete') || document.querySelectorAll('.ajamultiplete')) {
        // console.log("ajacomplete")
        var ajax = new XMLHttpRequest();
        ajax.open("GET", "/admin/media", true);
        ajax.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        ajax.setRequestHeader("Accept", "application/json");
        ajax.onload = function() {
            var list = JSON.parse(ajax.responseText);
            [].slice.call(document.querySelectorAll('.ajasomplete')).forEach(
                function(i) {
                    (new Awesomplete(i, {
                        maxItems: 30,
                        item: function(text, input) {
                            return Awesomplete.$.create("li", {
                                innerHTML: "<img src=/cache/imagecache/grid2/" + text.split('/').reverse()[0] + " ><span>" + text.split('/').reverse()[0] + "</span>",
                                "aria-selected": "false"
                            });
                        },
                        replace: function(text) {
                            this.input.value = text;
                            this.input.parentNode.previousElementSibling.innerHTML = "<img src=/cache/imagecache/thumbnail/" + text.split('/').reverse()[0] + " >";
                        }
                    })).list = list;
                }
            );
            // [].slice.call(document.querySelectorAll('.ajamultiplete')).forEach(
            //     function(i) {
            //         (new Awesomplete(i, {
            //             maxItems: 30,
            //             filter: function(text, input) {
            //                 return Awesomplete.FILTER_CONTAINS(text, input.match(/[^,]*$/)[0]);
            //             },
            //             item: function(text, input) {
            //                 return Awesomplete.$.create("li", {
            //                     innerHTML: "<img src=/cache/imagecache/grid2/" + text.split('/').reverse()[0] + " ><span>" + text.split('/').reverse()[0] + "</span>",
            //                     "aria-selected": "false"
            //                 });
            //             },
            //             replace: function(text) {
            //                 var before = this.input.value.match(/^.+,\s*|/)[0];
            //                 this.input.value = before + text + ", ";
            //                 // this.input.value = text
            //                 var imgs = this.input.value.split(',');
            //                 var html = "";
            //                 imgs.forEach(function(i){
            //                   i = i.trim();
            //                   if (i!=="")
            //                 html += "<img src=/cache/imagecache/thumbnail/" + i.split('/').reverse()[0] + " >";
            //                 });

            //                 this.input.parentNode.previousElementSibling.innerHTML = html ;
            //             }
            //         })).list = list;
            //     }
            // );
            [].slice.call(document.querySelectorAll('.ajamultiplete')).forEach(
                function(i) {
                    (new Awesomplete(i, {
                        maxItems: 30,
                        filter: function(text, input) {
                            return Awesomplete.FILTER_CONTAINS(text, input.match(/[^,]*$/)[0]);
                        },
                        item: function(text, input) {
                            return Awesomplete.$.create("li", {
                                innerHTML: "<img src=/cache/imagecache/grid2/" + text.split('/').reverse()[0] + " ><span>" + text.split('/').reverse()[0] + "</span>",
                                "aria-selected": "false"
                            });
                        },
                        replace: function(text) {
                            let name = this.input.dataset.name;
                            var before = this.input.value.match(/^.+,\s*|/)[0];
                            this.input.value = before + text + ", ";
                            // this.input.value = text
                            var imgs = this.input.value.split(',');
                            var html = "";
                            imgs.forEach(function(i){
                              i = i.trim();
                              if (i!==""){
                                let img =  i.split('/').reverse()[0];
                            html += "<img src=/cache/imagecache/thumbnail/" + img +  " >";
                              if (name){
                              html += `<input type=hidden name=${name} value="${img}" >`;
                              }
                              }
                            });

                            this.input.parentNode.previousElementSibling.innerHTML = html ;
                        },


                    })).list = list;
                    i.addEventListener("blur",function(e){
                      let name = this.dataset.name;

                        var imgs = this.value.split(',');
                        var html = "";
                        imgs.forEach(function(i){
                          i = i.trim();
                          if (i!==""){
                            let img =  i.split('/').reverse()[0];
                        html += "<img src=/cache/imagecache/thumbnail/" + img +  " >";
                          if (name){
                          html += `<input type=hidden name=${name} value="${img}" >`;
                          }
                          }
                        });

                        this.parentNode.previousElementSibling.innerHTML = html ;

                    });
                }
            );


        };

        ajax.send();
    }

});
if (document.querySelector('input[data-multiple]')) {

    new Awesomplete('input[data-multiple]', {
        filter: function(text, input) {
            return Awesomplete.FILTER_CONTAINS(text, input.match(/[^,]*$/)[0]);
        },

        replace: function(text) {
            var before = this.input.value.match(/^.+,\s*|/)[0];
            this.input.value = before + text + ", ";
        }
    });
}

$(function() {
  if ($.fn.sortable)
    $(".atys_dragdrop").sortable({
        handle: ".atys_handle",
        placeholder: "ui-state-highlight"
    });
});

$('.atys_range input').change(function() {
    // this.value = ch;
    this.parentElement.dataset.value = this.value;
});
document.querySelectorAll('[for^=meta]').forEach(function(e){
e.title=e.nextElementSibling.value.length;
e.nextElementSibling.addEventListener("input",function(){
this.previousElementSibling.title=this.value.length;
});
});
